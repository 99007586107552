// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

// Variables
@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

@import "~font-awesome/scss/font-awesome";

div.specialties>* {
    flex-basis: 50%;
    font-weight: bold;
    font-size: 1.2em;
    word-wrap: normal;
    word-break: keep-all;
    text-align: center;
}

div.specialties>div {
    align-items: center;
    column-gap: 15px;
}

@include media-breakpoint-up(lg) {
    div.specialties>* {
        font-size: 1.3rem;
        text-align: left;
    }
}

@include media-breakpoint-up(xl) {
    div.specialties>* {
        font-size: 1.5rem;
        flex-basis: 25%;
    }
}

.crumb {
    display: flex;
    align-items: center;

    width: auto;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    padding-left: 15px;
    padding-right: 15px;

    color: white;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
}

.crumb:nth-child(even) {
    border-left: 20px solid #10217d;
    background-color: #16c2d5;
}

.crumb:nth-child(odd) {
    border-left: 30px solid #16c2d5;
    background-color: #10217d;
}

.crumb:first-child {
    border-left: 0;
}

.crumb:last-child {
    background-color: transparent;
}

.crumb a,
.crumb a:hover,
.crumb a:visited,
.crumb a:active {
    text-decoration: none;
    color: white;
}

.border-bottom-black {
    border-bottom: 2px solid black;
}

#about {
    background-color: #89dee2;

    @include media-breakpoint-up(md) {
        border-left: 10px solid black;
    }
}

.conditions {
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: bold;
    column-count: 2;
}

.dropdown-states {
    column-count: 2;
    font-weight: bold;
}

.conditions li {
    border: 1px solid gray;
    padding: 15px;
    text-align: center;
    box-shadow: 1px 1px blue;
    margin-bottom: 10px;
}

@include media-breakpoint-up(lg) {

    ul.conditions,
    .dropdown-states {
        column-count: 4;
    }
}

.specialties img {
    max-width: 160px;
}

.hospital,
.practice {
    gap: 15px;
    align-items: center;
}

.hospital-icon,
.practice-icon {
    max-width: 100px;
}

.dr-name,
.dr-location {
    flex-basis: 100%;

    @include media-breakpoint-up(md) {
        flex-basis: 50%;
    }
}

.dr-name input,
.dr-location input {
    width: 100%;
    height: 50px;
}

#specChar,
#cityChar {
    width: 300px;
    height: 400px;

    @include media-breakpoint-up(lg) {
        width: 900px;
        height: 600px;
    }

    @include media-breakpoint-up(md) {
        width: 700px;
        height: 500px;
    }
}

#procedures {
    width: 100%;
    min-height: 400px;
}